import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/core/styles';
import cornerstoneTools from 'cornerstone-tools';
import _ from 'lodash';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

import { RoundedButtonGroup } from '@platform/ui';
import { CriteriaSection } from './sections/CriteriaSection';
import { MeasurementSection } from './sections/MeasurementSection';
import { FindingSection } from './sections/FindingSection';
import { ResponseSection } from './sections/ResponseSection';
import { AnalysisSection } from './sections/AnalysisSection';
import { AllLesionsSection } from './sections/AllLesionsSection';
import { getTimepoints } from './models/getTimepoints';
import { getClassifiedROIContours } from './models/responseAssessments';
import { getMeasurementSource } from './report/getMeasurementSource';
import { getAssessmentSource } from './report/getAssessmentSource';
import { getEndpointSource } from './report/getEndpointSource';
import { createCsvContent } from './report/exportCsv';
import { BODY_PARTS, TRACKING_TYPES } from './constants/guideline';
import theme from '../theme';

export const LongitudinalStudy = ({
  imageSets,
  PatientID,
  SeriesInstanceUID,
  defaultSectionName,
}) => {
  const editModule = cornerstoneTools.getModule('rtstruct-edit');
  const structureSet = editModule.getters.structureSet(SeriesInstanceUID);
  const [ROIContours, setROIContours] = useState(structureSet.ROIContours);
  const [sectionName, setSectionName] = useState(defaultSectionName);
  const [criteria, setCriteria] = useState(editModule.getters.criteria());
  const [assessments, setAssessments] = useState(
    editModule.getters.assessments()
  );
  const [plotType] = useState('measurement');
  const [plotFeature, setPlotFeature] = useState('byLesion');
  const [filteredTrackingType, setFilteredTrackingType] = useState('all');
  const [filteredBodyPart, setFilteredBodyPart] = useState('all');
  const [selectedLesionNumber, setSelectedLesionNumber] = useState(-1);
  const [measurementTechnique, setMeasurementTechnique] = useState(
    'BIDIMENSIONAL'
  );
  const [timepointIndex, setTimepointIndex] = useState(0);

  const onExportReportClick = async () => {
    const timepoints = getTimepoints(imageSets, ROIContours);
    const measurementSource = getMeasurementSource(timepoints, ROIContours);
    const measurementContent = createCsvContent(measurementSource);
    const assessmentSource = getAssessmentSource(timepoints, ROIContours);
    const assessmentContent = createCsvContent(assessmentSource);
    const endpointSource = getEndpointSource(assessmentSource, criteria);
    const endpointContent = createCsvContent(endpointSource);

    const zip = new JSZip();
    zip.file(`measurements.csv`, measurementContent);
    zip.file(`assessments.csv`, assessmentContent);
    zip.file(`endpoints.csv`, endpointContent);
    zip.generateAsync({ type: 'blob' }).then(function(blob) {
      saveAs(blob, `report_${PatientID}.zip`);
    });
  };

  const onExportLogsClick = async () => {
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(editModule.getters.logs())
    )}`;
    const link = document.createElement('a');
    link.href = jsonString;
    link.download = `logs_${Date.now()}_${PatientID}.json`;
    link.click();
  };

  const onAssessmentsChange = (seriesInstanceUID, key, input, reason) => {
    const index = assessments.findIndex(
      a => a.SeriesInstanceUID === seriesInstanceUID
    );
    const newAssessment = _.cloneDeep(assessments[index]);
    newAssessment[key] = input;
    if (
      [
        'targetResponse',
        'nonTargetResponse',
        'newLesionResponse',
        'steroidsUse',
        'clincalStatus',
        'customBaseline',
      ].includes(key)
    ) {
      newAssessment.overallResponse = '';
    }
    editModule.setters.assessment(seriesInstanceUID, newAssessment, reason);
    setAssessments([
      ...assessments.slice(0, index),
      newAssessment,
      ...assessments.slice(index + 1),
    ]);
  };

  const resetAssessments = () => {
    const newAssessments = _.cloneDeep(assessments);
    imageSets.forEach(series => {
      const assessment = newAssessments.find(
        a => a.SeriesInstanceUID === series.SeriesInstanceUID
      );
      if (assessment.responseAssessmentStatus !== 'confirmed') {
        assessment.targetResponse = '';
        assessment.nonTargetResponse = '';
        assessment.newLesionResponse = '';
        assessment.clinicalStatus = '';
        assessment.steroidsUse = '';
        assessment.overallResponse = '';
      }
      editModule.setters.assessment(series.SeriesInstanceUID, assessment);
    });
    setAssessments(newAssessments);
  };

  const onUpdateCriteriaClick = criteria => {
    /** set criteria in edit rtstruct module */
    editModule.setters.criteria(criteria);

    /**  classify and update the tracking type of roi contours */
    const timepoints = getTimepoints(imageSets, ROIContours);
    const classifiedROIContours = getClassifiedROIContours(
      timepoints,
      ROIContours,
      criteria
    );
    classifiedROIContours.forEach(ROIContour => {
      editModule.setters.updateROI(SeriesInstanceUID, ROIContour);
    });
    setROIContours(classifiedROIContours);
    setCriteria(criteria);
    window.alert('new criteria is set successfully');
  };

  const getFilteredROIContours = rois => {
    return rois
      .filter(roi => {
        switch (filteredTrackingType) {
          case 'all':
            return true;
          case 'measurable':
            return roi.measurable;
          case 'target':
            return roi.trackingType === TRACKING_TYPES.TARGET;
          case 'non-target':
            return roi.trackingType === TRACKING_TYPES.NONTARGET;
          case 'new':
            return roi.trackingType === TRACKING_TYPES.NEW;
          case 'selected':
            return roi.ROINumber === selectedLesionNumber;
          default:
            return false;
        }
      })
      .filter(roi => {
        switch (filteredBodyPart) {
          case 'all':
            return true;
          case 'brain':
            return roi.bodyPart === BODY_PARTS.BRAIN;
          case 'lung':
            return roi.bodyPart === BODY_PARTS.LUNG;
          case 'liver':
            return roi.bodyPart === BODY_PARTS.LIVER;
          case 'lymph-node':
            return roi.bodyPart === BODY_PARTS.LYMPHNODE;
          default:
            return false;
        }
      });
  };

  const onROIUpdate = (ROINumber, bodyPart, trackingType) => {
    const index = ROIContours.findIndex(roi => roi.ROINumber === ROINumber);
    const newROIContour = _.cloneDeep(ROIContours[index]);
    newROIContour.bodyPart = bodyPart;
    newROIContour.trackingType = trackingType;
    editModule.setters.updateROI(SeriesInstanceUID, newROIContour);
    const structureSet = editModule.getters.structureSet(SeriesInstanceUID);
    setROIContours([...structureSet.ROIContours]);
  };

  const onResponseConfirm = () => {};

  return (
    <ThemeProvider theme={theme}>
      <div style={{ margin: '0 0 10px 0' }}>
        <RoundedButtonGroup
          options={[
            { value: 'criteria', label: 'Criteria' },
            { value: 'measurement', label: 'Measurements' },
            { value: 'finding', label: 'Findings' },
            { value: 'analysis', label: 'Analysis' },
            { value: 'all-lesions', label: 'All Lesions' },
            { value: 'response', label: 'Responses' },
          ]}
          value={sectionName}
          onValueChanged={value => {
            setSectionName(value);
          }}
        />
      </div>
      <div style={{ height: '70vh', minHeight: '300px', maxHeight: '70vh' }}>
        {sectionName === 'criteria' && (
          <CriteriaSection
            imageSets={imageSets}
            ROIContours={ROIContours}
            criteria={criteria}
            onUpdateCriteriaClick={onUpdateCriteriaClick}
          />
        )}
        {sectionName === 'measurement' && (
          <MeasurementSection
            imageSets={imageSets}
            ROIContours={ROIContours}
            onROIUpdate={onROIUpdate}
            getFilteredROIContours={getFilteredROIContours}
            filteredTrackingType={filteredTrackingType}
            setFilteredTrackingType={setFilteredTrackingType}
            filteredBodyPart={filteredBodyPart}
            setFilteredBodyPart={setFilteredBodyPart}
            selectedLesionNumber={selectedLesionNumber}
            setSelectedLesionNumber={setSelectedLesionNumber}
            timepointIndex={timepointIndex}
            setTimepointIndex={setTimepointIndex}
          />
        )}
        {sectionName === 'finding' && (
          <FindingSection
            imageSets={imageSets}
            ROIContours={ROIContours}
            criteria={criteria}
            assessments={assessments}
            getFilteredROIContours={getFilteredROIContours}
            filteredTrackingType={filteredTrackingType}
            setFilteredTrackingType={setFilteredTrackingType}
            filteredBodyPart={filteredBodyPart}
            setFilteredBodyPart={setFilteredBodyPart}
            selectedLesionNumber={selectedLesionNumber}
            setSelectedLesionNumber={setSelectedLesionNumber}
            measurementTechnique={measurementTechnique}
            setMeasurementTechnique={setMeasurementTechnique}
          />
        )}
        {sectionName === 'analysis' && (
          <AnalysisSection
            imageSets={imageSets}
            ROIContours={ROIContours}
            criteria={criteria}
            assessments={assessments}
            plotType={plotType}
            getFilteredROIContours={getFilteredROIContours}
            filteredTrackingType={filteredTrackingType}
            setFilteredTrackingType={setFilteredTrackingType}
            filteredBodyPart={filteredBodyPart}
            setFilteredBodyPart={setFilteredBodyPart}
            selectedLesionNumber={selectedLesionNumber}
            setSelectedLesionNumber={setSelectedLesionNumber}
            measurementTechnique={measurementTechnique}
            setMeasurementTechnique={setMeasurementTechnique}
          />
        )}
        {sectionName === 'all-lesions' && (
          <AllLesionsSection
            imageSets={imageSets}
            ROIContours={ROIContours}
            plotFeature={plotFeature}
            setPlotFeature={setPlotFeature}
            getFilteredROIContours={getFilteredROIContours}
            filteredBodyPart={filteredBodyPart}
            setFilteredBodyPart={setFilteredBodyPart}
            measurementTechnique={measurementTechnique}
            setMeasurementTechnique={setMeasurementTechnique}
          />
        )}
        {sectionName === 'response' && (
          <ResponseSection
            imageSets={imageSets}
            ROIContours={ROIContours}
            criteria={criteria}
            assessments={assessments}
            onAssessmentsChange={onAssessmentsChange}
            resetAssessments={resetAssessments}
            onResponseConfirm={onResponseConfirm}
            onExportReportClick={onExportReportClick}
            onExportLogsClick={onExportLogsClick}
          />
        )}
      </div>
    </ThemeProvider>
  );
};
LongitudinalStudy.propTypes = {
  imageSets: PropTypes.arrayOf(
    PropTypes.shape({ SeriesInstanceUID: PropTypes.string })
  ),
  PatientID: PropTypes.string,
  SeriesInstanceUID: PropTypes.string,
  defaultSectionName: PropTypes.string,
};
