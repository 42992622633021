import _ from 'lodash';
import {
  BODY_PARTS,
  MEASUREMENT_TECHNIQUE,
  TRACKING_TYPES,
} from '../../constants/guideline.js';

/**
 * @param {Object[]} timepoints
 * @param {Object[]} ROIContours
 * @param {String} criteria.measurementTechnique
 * @param {Number} criteria.measurableLongAxis
 * @param {Number} criteria.measurableShortAxis
 * @param {Number} criteria.measurableVolume
 * @param {Number} criteria.maxNumberOfTargets
 * @param {Number} criteria.maxNumberOfTargetsPerOrgan
 */
function getClassifiedROIContours(
  timepoints,
  ROIContours,
  criteria,
  forceUpdate = true
) {
  /**
   * classified: ['target', 'non-target', 'new', 'not-included']
   * unClassified: ['none', 'isolated']
   */
  const unClassifiedROIContours = [];
  const classifiedROIContours = [];
  _.cloneDeep(ROIContours).forEach(roi => {
    if (
      forceUpdate ||
      !roi.trackingType ||
      [TRACKING_TYPES.NONE, TRACKING_TYPES.ISOLATED].includes(roi.trackingType)
    ) {
      unClassifiedROIContours.push(roi);
    } else {
      classifiedROIContours.push(roi);
    }
  });

  /** define baseline lesions */
  const baselineLesions =
    timepoints[0]?.Lesions?.filter(roi => roi.maxAreaValue > 0) || [];

  /** find measurable lesions */
  const _measurableLesions = [];
  switch (criteria.measurementTechnique) {
    case MEASUREMENT_TECHNIQUE.UNIDIMENSIONAL:
      baselineLesions.sort(function(a, b) {
        return Number(b.maxDiameterValue) - Number(a.maxDiameterValue);
      });
      _measurableLesions.push(
        ...baselineLesions.filter(roi => {
          if (roi.bodyPart === BODY_PARTS.LYMPHNODE) {
            return (
              roi.maxShortAxisValue >= criteria.measurableShortAxisOfLymphNode
            );
          }
          return roi.maxLongAxisValue >= criteria.measurableLongAxis;
        })
      );
      break;
    case MEASUREMENT_TECHNIQUE.BIDIMENSIONAL:
      baselineLesions.sort(function(a, b) {
        return Number(b.maxDiameterValue) - Number(a.maxDiameterValue);
      });
      _measurableLesions.push(
        ...baselineLesions.filter(roi => {
          if (roi.bodyPart === BODY_PARTS.LYMPHNODE) {
            return (
              roi.maxShortAxisValue >= criteria.measurableShortAxisOfLymphNode
            );
          }
          return (
            roi.maxLongAxisValue >= criteria.measurableLongAxis &&
            roi.maxShortAxisValue >= criteria.measurableShortAxis
          );
        })
      );
      break;
    case MEASUREMENT_TECHNIQUE.VOLUMETRIC:
      baselineLesions.sort(function(a, b) {
        return Number(b.volume) - Number(a.volume);
      });
      _measurableLesions.push(
        ...baselineLesions.filter(
          roi => roi.volume >= criteria.measurableVolume
        )
      );
      break;
    default:
  }
  const measurableLesions = prioritizeIsolatedLesions(_measurableLesions);
  const measurableLesionNumber = measurableLesions.map(roi => roi.ROINumber);

  /** find target lesions */
  let counts = 0;
  let countsPerOrgan = {};
  const baselineLesionNumber = baselineLesions.map(roi => roi.ROINumber);
  const targetLesionNumber = [];
  measurableLesions.forEach(roi => {
    if (!countsPerOrgan[roi.bodyPart]) countsPerOrgan[roi.bodyPart] = 0;
    if (
      counts >= criteria.maxNumberOfTargets ||
      countsPerOrgan[roi.bodyPart] >= criteria.maxNumberOfTargetsPerOrgan
    ) {
      return;
    }
    if (
      [
        BODY_PARTS.PLEURA,
        BODY_PARTS.PLEURALEFFUSION,
        // BODY_PARTS.OTHERS,
      ].includes(roi.bodyPart)
    ) {
      return;
    }
    targetLesionNumber.push(roi.ROINumber);
    counts += 1;
    countsPerOrgan[roi.bodyPart] += 1;
  });
  unClassifiedROIContours.forEach(roi => {
    if (targetLesionNumber.includes(roi.ROINumber)) {
      roi.trackingType = TRACKING_TYPES.TARGET;
    } else if (baselineLesionNumber.includes(roi.ROINumber)) {
      roi.trackingType = TRACKING_TYPES.NONTARGET;
    } else {
      roi.trackingType = TRACKING_TYPES.NEW;
    }
  });

  /** exclude others */
  unClassifiedROIContours.forEach(roi => {
    if (
      [
        BODY_PARTS.PLEURA,
        BODY_PARTS.PLEURALEFFUSION,
        // BODY_PARTS.OTHERS,
      ].includes(roi.bodyPart)
    ) {
      roi.trackingType = TRACKING_TYPES.NOTINCLUDED;
    }
  });

  /** sort by roi number and label measurability */
  const newROIContours = [...classifiedROIContours, ...unClassifiedROIContours];
  newROIContours.sort((a, b) => Number(a.ROINumber) - Number(b.ROINumber));
  newROIContours.forEach(roi => {
    roi.measurable = measurableLesionNumber.includes(roi.ROINumber);
  });
  return newROIContours;
}

function prioritizeIsolatedLesions(measurableLesions) {
  const isolated = [];
  const nonIsolated = [];
  measurableLesions.forEach(roi => {
    if (roi.trackingType === TRACKING_TYPES.ISOLATED) {
      isolated.push(roi);
    } else {
      nonIsolated.push(roi);
    }
  });
  return [...isolated, ...nonIsolated];
}

export default getClassifiedROIContours;
